@import "styles/variables.scss";

.productCard {

  a {
    color: $text-color;

    &:hover {
      .content {
        box-shadow: 0 0 12px rgba(105, 78, 78, 0.5);
        transform: scale(1.05);

        .bottom {
          .button {
            transition: all linear 0.15s;
            color: rgba($text-color, 0.4);
            transform: translateX(4px);
          }
        }
      }
    }
  }

  .content {
    border: 1px solid $grey-border-color;
    border-radius: $general-radius;
    transition: all 0.3s;
    margin-top: 15px;
    border-radius: $general-radius;
    overflow: hidden;
    background: #ffffff;

    .imgWrapper {
      position: relative;
      img {
        max-width: 100%;
        width: 100%;
      }
    }

    .description {
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      opacity: 0.6;
      margin-bottom: 10px;

      > div {
        display: flex;
        justify-content: space-between;

        > span:nth-child(2) {
          font-weight: 700;
        }
      }
    }

    .details {
      padding: 15px;
      .brand {
        display: inline-block;
        padding: 3px 10px;
        background-color: $brand-color;
        color: white;
        font-size: 0.7em;
      }
      .name {
        // font-weight: bold;
        // font-weight: 300;
        font-size: 1.1em;
        height: 50px;
        overflow: hidden;
        margin-top: 5px;
      }
    }

    .bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      border-top: 1px solid $grey-border-color;
      padding-top: 5px;
      .price {
        font-size: 16px;
        font-weight: 600;
        opacity: 0.7;
        span[data-property="priceWithoutVat"] {
          font-size: 10px;
          line-height: 10px;
          font-weight: normal;
          &:after {
            content: " bez DPH";
          }
        }
      }
      .button {
        color: rgba($text-color, 0.2);
        display: none;
        margin-left: -4px;
        transition: all linear 0.15s;
        @media (min-width: $sm) {
          display: block;
        }
      }
    }
  }
}
