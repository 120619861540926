@import "styles/variables";

.title {
  margin-bottom: 0;
}

.description {
  margin-bottom: 20px;
  color: $text-color;
}


.subcategories {
    button {
        padding: 7px 15px;
        height: auto;
    }
}