.headline {
  padding-bottom: 5px;
  font-size: 1.3em
}

.wrapper {
  margin: 30px 0;
  // background-color: rgba(0,0,0,0.02);
  border-radius: 5px;
  padding: 15px 0;
}

.carouselWrapper {
  position: relative;
  padding: 0 60px;

  &::after,
  &::before {
    display: block;
    content: '';
    width: 60px;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 998;
  }

  &::before {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 35%, white 100%);
    left: 0;
  }

  &::after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 35%, white 100%);
    right: 0;
  }
}

.promotedProducts {
  :global(.slick-list) {
    overflow: visible;
  }

  :global(.slick-arrow) {
    z-index: 999;
    background: #ffffff;
    border: 1px solid rgba(0,0,0,0.1);
    // width: 35px;
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    margin-top: -25px;
    // border-radius: 3px;;
    transition: all 0.2s ease-in-out;
    font-size: 15px;
    line-height: 47px;

    &:hover,
    &:focus {
      background: #ffffff;

      &::before {
        color:  rgba(0,0,0,0.6);
      }
    }

    &::before {
      display: inline-block;
      color:  rgba(0,0,0,0.4);
    }
  }

  :global(.slick-prev) {
    left: -80px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    padding-right: 10px;
    text-align: right;


    &:hover {
      left: -70px;
    }
  }

  :global(.slick-next) {
    right: -80px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    padding-left: 10px;
    text-align: left;

    &:hover {
      right: -70px;
    }
  }
}
