.productPromotedCard {
  display: inline-flex;
  flex-flow: column;
  justify-content: space-between;
  border: 1px solid white;
  border-radius: 5px;
  background: white;
  text-decoration: none;
  transition: all 0.1s linear 0s;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin: 0 5px;

  img {
    width: 100%;
    padding: 5px 10px;
  }

  &:hover {
    z-index: 2;
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 25px 60px -12px rgba(0,0,0,0.15);
  }

  .content {
    .name {
      font-size: 0.7em;
      line-height: 1.2em;
      color: rgb(80, 35, 122);
      display: flex;
      text-decoration: none;
      margin: 0px;
      height: 51px;
      padding: 15px;
    }

    .bottomRow {
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      box-sizing: border-box;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      padding: 15px 15px 10px 15px;

      .price {
        display: block;
        & span[data-property="price"] {
          display: block;
          color: rgb(175, 202, 5);
          font-weight: bolder;
          text-align: left;
          white-space: nowrap;
          text-decoration: none;
          font-size: 0.9em;
          line-height: 0.9em;
        }
        & span[data-property="priceWithoutVat"] {
          white-space: nowrap;
          color: rgb(170, 170, 170);
          font-weight: lighter;
          font-size: 0.7em;
          line-height: 0.7em;
          text-decoration: none;
        }
      }

      .button {
        min-width: 45%;
        display: inline-flex;
        cursor: pointer;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
        box-sizing: border-box;
        font-size: 0.7em;
        font-weight: bolder;
        width: auto;
        height: 24px;
        color: rgb(80, 35, 122);
        -webkit-appearance: none !important;
        transition: all 0.3s linear 0s;
        text-decoration: none;
        padding: 5px 0.6em;
        background: transparent;
        border-width: 1px;
        border-style: solid;
        border-image: initial;
        border-color: rgb(175, 202, 5);
        border-radius: 100px;
        margin: 0px;
        &:hover {
          color: rgb(33, 0, 77);
          text-decoration: none;
          border-color: rgb(123, 153, 0);
        }
      }
    }
  }
}
