@import "styles/variables";

.wrapper {
  transition: all 0.3s;
  background: #ffffff;
  border-radius: $general-radius;
  padding: 10px 15px;

  &.loading {
    opacity: 0.25;
  }

  .headline {
    font-size: 0.95em;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    color: $text-color;
    font-weight: bolder;
  }

  .parameter {
    padding: $general-gutter 0;
    .spaceWrapper {
      display: flex;
      justify-content: space-between;
    }
    .parameterContent {
      display: block;
      position: relative;
    }
  }

  .linkShowMore {
    cursor: pointer;
    -webkit-box-pack: start;
    justify-content: flex-start;
    box-sizing: border-box;
    text-decoration-line: underline;
    text-decoration-style: initial;
    text-decoration-color: initial;
    text-decoration: underline;
    width: auto;
    color: $primary-color;
    font-size: 0.8em;
    display: block;
    margin-top: 5px;
    &:hover {
      text-decoration: none;
    }
  }
}
